// React
import React from "react";
import Helmet from "react-helmet";

// Internal dependencies
import "./index.css";

import Shell from "../../components/shell/shell";
import Header from "../../components/header/header";
import Main from "../../components/main/main";

import Navbar from "../../components/navbar/navbar";
import NavbarSection from "../../components/navbar-section/navbar-section";
import NavbarLogo from "../../components/navbar-logo/navbar-logo";
import NavbarItem from "../../components/navbar-item/navbar-item";


const DonatePage = () =>
(
    <Shell classNames={["donate-page-shell"]}>

        {/* HELMET */}
        <Helmet>
            <title>GoodSpot - Donate</title>
        </Helmet>

        {/* HEADER */}
        <Header>
            <Navbar>

                <NavbarSection>
                    <NavbarLogo
                        src="https://via.placeholder.com/64" 
                        alt="Logo"
                        to="/" />
                </NavbarSection>

                <NavbarSection>
                    <NavbarItem
                        label="About"
                        to="/about/" />
                    <NavbarItem
                        label="Donate"
                        to="/donate/" />
                    <NavbarItem
                        label="Contact" 
                        to="/contact/" />
                    <NavbarItem
                        label="Join"
                        to="/join/" />
                </NavbarSection>

            </Navbar>
        </Header>

        {/* MAIN */}
        <Main>
            Donate content
        </Main>

    </Shell>
);

export default DonatePage;